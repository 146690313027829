import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Navbar, Footer, Sidebar } from "./components";
import { SolarChart, Impact, Numbers, Distribution, TopTen } from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";

const App = () => {
  const { activeMenu } = useStateContext();
  const theme = createTheme();

  return (
    <div className={"dark"}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg min-h-screen md:ml-72 w-full  "
                  : "dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
              }
            >
              <div className="fixed md:static dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                <Routes>
                  {/* dashboard  */}
                  <Route path="/" element={<SolarChart />} />
                  <Route path="/impact" element={<Impact />} />
                  <Route path="/distribution" element={<Distribution />} />
                  <Route path="/numbers" element={<Numbers />} />
                  <Route path="/top" element={<TopTen />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
