import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  StackingColumnSeries,
} from "@syncfusion/ej2-react-charts";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { format, startOfYear, endOfYear, startOfMonth, endOfMonth, subYears } from 'date-fns'

import { ChartsHeader } from "../components";

import "../App.css";

class OldSolarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    };
    this.palette = ["#EA9300", "#72FF13", "#5500FF"];
    this.primaryXAxis = { interval: 1, valueType: "Category" };
    this.primaryYAxis = {
      rangePadding: "None",
      labelFormat: "{value} kWh",
    };
    this.marker = { visible: true };
    this.animation = { enable: true, duration: 1200, delay: 5000 };
  }

  formatDisplayDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", options);
  };

  onChange = (props) => {
    if (props) {
      this.setState({
        startDate: format(props.startDate, 'yyyy-MM-dd'),
        endDate: format(props.endDate, 'yyyy-MM-dd'),
      });
    }
    new DataManager({
      url: `https://teslasolar.report/api/v2/formatted?start_date=${format(props.startDate, 'yyyy-MM-dd')}&end_date=${format(props.endDate, 'yyyy-MM-dd')}`,
    })
      .executeQuery(new Query())
      .then((response) => {
        this.setState({ data: response.result.data });
      });
  };

  componentDidMount() {
    new DataManager({
      url: `https://teslasolar.report/api/v2/formatted?start_date=${
        this.state.startDate
      }&end_date=${this.state.endDate}`,
    })
      .executeQuery(new Query())
      .then((response) => {
        this.setState({ data: response.result.data });
      });
  }

  render() {
    const { data } = this.state;
    const presets = [
      {
        label: "Current Month",
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      },
      {
        label: "Current Year",
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
      },
      {
        label: "Current True Up Year",
        start: subYears(new Date(new Date().getFullYear(),9,23), 1),
        end: new Date(new Date().getFullYear(),9,22),
      },
    ];

    return (
      <div>
        <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl">
          <div className="flex justify-end pt-10 p-2 md:ml-6 md:mr-6 relative">
            <div style={{ width: "300px" }}>
              <DateRangePickerComponent
                id="daterangepicker"
                placeholder="Select Date Range"
                change={this.onChange}
                min={new Date(2021, 9, 10)}
                strictMode={true}
                presets={presets}
                openOnFocus={true}
              />
            </div>
          </div>
          <ChartsHeader
            dateRange={
              this.state.startDate !== ""
                ? `${this.formatDisplayDate(
                    this.state.startDate
                  )} - ${this.formatDisplayDate(this.state.endDate)}`
                : ""
            }
            title="Tesla Solar Report"
          />
          <div className="w-full">
            <ChartComponent
              id="charts"
              primaryXAxis={this.primaryXAxis}
              primaryYAxis={this.primaryYAxis}
              palettes={this.palette}
              tooltip={{ enable: true }}
              style={{ height: "600px" }}
              loaded={this.onChartLoaded.bind(this)}
            >
              <Inject
                services={[
                  StackingColumnSeries,
                  Category,
                  Legend,
                  DataLabel,
                  Tooltip,
                ]}
              />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={data}
                  xName="date"
                  yName="solar"
                  name="Solar"
                  legendShape="Circle"
                  type="StackingColumn"
                  animation={this.animations}
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={data}
                  xName="date"
                  yName="grid"
                  type="StackingColumn"
                  name="Grid"
                  legendShape="Circle"
                  animation={this.animations}
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={data}
                  xName="date"
                  yName="house"
                  name="House"
                  legendShape="Circle"
                  type="StackingColumn"
                  animation={this.animations}
                ></SeriesDirective>
              </SeriesCollectionDirective>
            </ChartComponent>
            <p className="flex justify-center p-2 md:ml-6 md:mr-6 relative text-gray-400 italic">Grid usage with a negative number indicates an excess of engery sent back to the grid.</p>
          </div>
        </div>
      </div>
    );
  }
  onChartLoaded(args) {
    let chart = document.getElementById("charts");
    let legendTextCol = chart.querySelectorAll('[id*="chart_legend_text_"]');
    for (let i = 0; i < legendTextCol.length; i++) {
      //set the color to legend label
      legendTextCol[i].setAttribute("fill", "rgb(229 231 235)");
    }
  }
}

export default OldSolarChart;
