import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const ChartsHeader = ({dateRange, title }) => {
  const { modified } = useStateContext();

  return (
    <div className=" mb-10">
      <p className="dark:text-gray-400">Last Updated at {modified}</p>
      <p className="text-center font-extrabold dark:text-gray-200 text-3xl mb-2 mt-3">{title}</p>
      <p className="text-center text-lg text-gray-400">{dateRange}</p>
    </div>
  )
};

export default ChartsHeader;
