import React from "react";
import {
  BsFillSunFill,
  BsFillFileSpreadsheetFill,
  BsTrophyFill,
} from "react-icons/bs";
import { ImLeaf } from "react-icons/im";
import { GiGreenPower } from "react-icons/gi";

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "solar by date range",
        redirect: "/",
        icon: <BsFillSunFill />,
      },
      {
        name: "impact",
        redirect: "/impact",
        icon: <ImLeaf />,
      },
      {
        name: "distribution",
        redirect: "/distribution",
        icon: <GiGreenPower />,
      },
      {
        name: "by the numbers",
        redirect: "/numbers",
        icon: <BsFillFileSpreadsheetFill />,
      },
      {
        name: "top ten",
        redirect: "/top",
        icon: <BsTrophyFill />,
      },
    ],
  },
];
