import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Header = ({ title, subtitle }) => {
  const { modified } = useStateContext();

  return (
    <div className=" mb-10">
      <p className="dark:text-gray-400 text-right">Last Updated at {modified}</p>
      <p className="text-3xl font-extrabold tracking-tight dark:text-gray-200">
        {title}
      </p>
        <p className="text-lg text-gray-400">{subtitle}</p>
    </div>
  )
};

export default Header;
