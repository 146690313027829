import React, {useEffect} from "react";
import {MotionPathPlugin} from "gsap/MotionPathPlugin"; 
import gsap from 'gsap';

import { useStateContext } from '../contexts/ContextProvider';

gsap.registerPlugin(MotionPathPlugin);

const Distribution = () => {
  const { batteryHouse, gridBattery, gridHouse, solarBattery, solarGrid, solarHouse, solarPower, loadPower, batteryPower, gridPower, battery, batteryStatus } = useStateContext();

  useEffect(() => {
    gsap.to("#bh", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#bh-path",
          align: "#bh-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 1,
          end: 0
      }})
    gsap.to("#sh", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#sh-path",
          align: "#sh-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 1,
          end: 0
      }
    })
    gsap.to("#sb", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#sb-path",
          align: "#sb-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 0,
          end: 1
      }
    })
    gsap.to("#sg", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#sg-path",
          align: "#sg-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 1,
          end: 0
      }
    })
    gsap.to("#gh", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#gh-path",
          align: "#gh-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 0,
          end: 1
      }
    })
    gsap.to("#gb", {
      duration: 3,
      repeat: -1,
      repeatDelay: 0,
      yoyo: false,
      ease: "power2",
      motionPath:{
          path: "#gb-path",
          align: "#gb-path",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
          start: 0,
          end: 1
      }
    })
  },[batteryHouse, gridBattery, gridHouse, solarBattery, solarGrid, solarHouse])

  return (
    <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl flex justify-center">
      <div className="flex justify-center align-center pt-10 md:ml-6 md:mr-6 relative" style={{width: '1000px'}}>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540" width="100%" height="100%">
          <defs>
            <filter id="f1" x="0" y="0">
              <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" />
            </filter>
            <linearGradient id="linear-gradient" x1="99" y1="270" x2="441" y2="270" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#a5adad"/>
              <stop offset=".45" stop-color="#a5acab"/>
              <stop offset=".55" stop-color="#193ef8"/>
              <stop offset="1" stop-color="#183efa"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="270" y1="99" x2="270" y2="441" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#ea9300"/>
              <stop offset=".45" stop-color="#e59105"/>
              <stop offset=".55" stop-color="#65e334"/>
              <stop offset="1" stop-color="#72ff13"/>
            </linearGradient>
            <linearGradient id="linear-gradient-3" x1="99" y1="181.75" x2="264.5" y2="181.75" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#a5adad"/>
              <stop offset=".4" stop-color="#a5acab"/>
              <stop offset=".55" stop-color="#a8aba4"/>
              <stop offset=".65" stop-color="#ada998"/>
              <stop offset=".74" stop-color="#b3a787"/>
              <stop offset=".81" stop-color="#bca471"/>
              <stop offset=".87" stop-color="#c79f56"/>
              <stop offset=".93" stop-color="#d49b36"/>
              <stop offset=".98" stop-color="#e29511"/>
              <stop offset="1" stop-color="#ea9300"/>
            </linearGradient>
            <linearGradient id="linear-gradient-4" x1="275.5" y1="181.75" x2="440.36" y2="181.75" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#ea9300"/>
              <stop offset="0" stop-color="#e59105"/>
              <stop offset=".04" stop-color="#bf8132"/>
              <stop offset=".09" stop-color="#9b735d"/>
              <stop offset=".14" stop-color="#7c6682"/>
              <stop offset=".19" stop-color="#605ba3"/>
              <stop offset=".25" stop-color="#4952be"/>
              <stop offset=".32" stop-color="#374ad4"/>
              <stop offset=".4" stop-color="#2944e5"/>
              <stop offset=".49" stop-color="#1f40f1"/>
              <stop offset=".63" stop-color="#193ef8"/>
              <stop offset="1" stop-color="#183efa"/>
            </linearGradient>
            <linearGradient id="linear-gradient-5" x1="275.5" y1="358.25" x2="440.36" y2="358.25" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#72ff13"/>
              <stop offset=".03" stop-color="#65e334"/>
              <stop offset=".07" stop-color="#55c05d"/>
              <stop offset=".12" stop-color="#46a182"/>
              <stop offset=".18" stop-color="#3986a3"/>
              <stop offset=".24" stop-color="#2f6fbe"/>
              <stop offset=".31" stop-color="#265dd4"/>
              <stop offset=".39" stop-color="#1f4fe5"/>
              <stop offset=".48" stop-color="#1b45f1"/>
              <stop offset=".62" stop-color="#183ff8"/>
              <stop offset="1" stop-color="#183efa"/>
            </linearGradient>
            <linearGradient id="linear-gradient-6" x1="99.64" y1="358.25" x2="264.5" y2="358.25" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#a5adad"/>
              <stop offset=".41" stop-color="#a4adab"/>
              <stop offset=".56" stop-color="#a2b1a4"/>
              <stop offset=".67" stop-color="#9eb798"/>
              <stop offset=".75" stop-color="#98c087"/>
              <stop offset=".83" stop-color="#91cc71"/>
              <stop offset=".89" stop-color="#88db56"/>
              <stop offset=".95" stop-color="#7deb36"/>
              <stop offset="1" stop-color="#72ff13"/>
            </linearGradient>
            <linearGradient id="charge-progress" x1="-.015" y1="1" x2="0" y2="0"> 
              <stop id="stop1" offset={battery/100} stop-color="#71FF12"/> 
              <stop id="stop2" offset={battery/100 - .1} stop-color="#000000"/> 
            </linearGradient>
            <style>
              {`.cls-7{fill:#ea9300}
                .cls-8{fill:#a5adad}
                .cls-9{fill:#183efa}
                .cls-10{fill:#72ff13}
                .st0{fill:#FFFFFF;}
                .st1{font-family:'ArialMT';}
                .st2{font-size:12px;}
                .st3{filter:url(#f1);}
                .st4{fill:#999999;}
                .st5{fill:#B8BFC7;}
                .st6{fill:#72FF13;}
                .light-grey{fill:#eee;}
                .medium-grey{fill:#e8e8e6;}
                .dark-grey{fill:#a3a39f;}
                .logo{fill:#5e5e5e;}`}
            </style>
          </defs>
          <path id="grid" class="cls-8" d="M50 222c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48m0-2c-27.61 0-50 22.39-50 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50Z"/>
          <g><text x="35" y="210" fontSize="10" fontWeight="bold" fill="#ffffff">{`${gridPower} kW`}</text></g>
          <path id="load" class="cls-9" d="M490 222c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48m0-2c-27.61 0-50 22.39-50 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50Z"/>
          <g><text x="475" y="210" fontSize="10" fontWeight="bold" fill="#ffffff">{`${loadPower} kW`}</text></g>
          <path id="gh-path" style={{stroke:'url(#linear-gradient)',fill:'none',strokeMiterlimit:'10'}} d="M99 270h342" visibility={gridHouse}/>
          <path id="solar" class="cls-7" d="M270 2c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48m0-2c-27.61 0-50 22.39-50 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50Z"/>
          <g><text x="330" y="55" fontSize="10" fontWeight="bold" fill="#ffffff">{`${solarPower} kW`}</text></g>
          <path id="battery" class="cls-10" d="M270 442c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48m0-2c-27.61 0-50 22.39-50 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50Z"/>
          <g><text x="330" y="490" fontSize="10" fontWeight="bold" fill="#ffffff">{`${batteryPower} kW - ${battery}%`}</text></g>
          <g><text x="330" y="500" fontSize="7" fill="#ffffff">POWERWALL - 3x</text></g>
          <path id="sb-path" style={{stroke:'url(#linear-gradient-2)',fill:'none',strokeMiterlimit:'10'}} d="M270 99v342" visibility={solarBattery}/>
          <path id="sg-path" d="M99 264h160c2.76 0 5-2.24 5-5V99" style={{stroke:'url(#linear-gradient-3)',fill:'none',strokeMiterlimit:'10'}} visibility={solarGrid}/>
          <path id="sh-path" d="M440.36 264H281c-2.76 0-5-2.24-5-5V99" style={{stroke:'url(#linear-gradient-4)',fill:'none',strokeMiterlimit:'10'}} visibility={solarHouse}/>
          <path id="bh-path" d="M440.36 276H281c-2.76 0-5 2.24-5 5v160" style={{stroke:'url(#linear-gradient-5)',fill:'none',strokeMiterlimit:'10'}} visibility={batteryHouse}/>
          <path id="gb-path" d="M99.64 276H259c2.76 0 5 2.24 5 5v160" style={{stroke:'url(#linear-gradient-6)',fill:'none',strokeMiterlimit:'10'}} visibility={gridBattery}/>
          <circle id="sg" class="cls-7" cx="264" cy="108.59" r="2.5" filter="url(#f1)" visibility={solarGrid}/>
          <circle id="sb" class="cls-7" cx="270" cy="108.59" r="2.5" filter="url(#f1)" visibility={solarBattery}/>
          <circle id="sh" class="cls-7" cx="276" cy="108.59" r="2.5" filter="url(#f1)" visibility={solarHouse}/>
          <circle id="gh" class="cls-8" cx="105.69" cy="270" r="2.5" filter="url(#f1)" visibility={gridHouse}/>
          <circle id="gb" class="cls-8" cx="264" cy="419.52" r="2.5" filter="url(#f1)" visibility={gridBattery}/>
          <circle id="bh" class="cls-10" cx="276" cy="431.33" r="2.5" filter="url(#f1)" visibility={batteryHouse}/>
          <path class="cls-9" d="m521.85 263.52-11.13-9.58v-10.87c0-1.55-1.04-2.59-2.59-2.59h-5.18c-1.55 0-2.59 1.04-2.59 2.59v2.33l-8.54-7.25c-1.04-.78-2.59-.78-3.62 0l-30.03 25.37c-1.29 1.04-.52 2.85 1.04 2.85h4.92v28.48c0 1.55 1.04 2.59 2.59 2.59h15.53v-15.53c0-4.4 3.37-7.77 7.77-7.77s7.77 3.37 7.77 7.77v15.53h15.53c1.55 0 2.59-1.04 2.59-2.59v-28.48h4.92c1.55 0 2.33-1.81 1.04-2.85Z"/>
          <path class="cls-8" d="M68.01 237.5H32l-14.5 21.77v13.98H24v-9.75h6.5v39h39v-39H76v9.75h6.5v-13.98L68.01 237.5Zm-26.33 45.83L63 274.81v19.17l-21.32-10.66ZM37 244h15.47L37 251.74V244Zm0 34.19v-16.25L57.51 270 37 278.19Zm4.68-21.52L63 246.01v19.18l-21.32-8.52Zm-14.85.32 3.67-5.52v5.52h-3.67ZM37 288.26l15.47 7.73H37v-7.73Zm32.5-36.79 3.67 5.52H69.5v-5.52Z" id="Layer_2"/>
          <path class="cls-7" d="M264.9,70.3h2.2v12.2h-2.2V70.3z M295.5,32.5l3-1.8l-3-1.8l2.2-2.8l-3.5-0.7l1.1-3.4l-3.5,0.6l-0.1-3.5l-3.1,1.7l-1.3-3.3
	l-2.3,2.7l-2.3-2.7l-1.3,3.3l-3.1-1.7l-0.1,3.5l-3.5-0.6l1.1,3.4l-3.5,0.7l2.2,2.8l-3,1.8l3,1.8h4.6l4.1,10.6l1.7-1.9l2.3,2.7
	l1.3-3.3l3.1,1.7l0.1-3.5l3.5,0.6l-1.2-3.4l3.5-0.7L295.5,32.5z M260.1,39h-12.6l4.3,11.2h12.6L260.1,39z M275.2,39h-12.6l4.3,11.2
	h12.6L275.2,39z M265.3,52.5h-12.6l4.3,11.2h12.6L265.3,52.5z M280.4,52.5h-12.6l4.3,11.2h12.6L280.4,52.5z M290.6,68h-36.4
	l-12.7-33.3h36.4L290.6,68z M287.9,66l-6-15.7l-5.2-13.5h-32.4L255.4,66L287.9,66L287.9,66z"/>
          {/* <rect class="cls-10" x="248.34" y="466.15" width="43.32" height="56.35" rx="4.33" ry="4.33"/>
          <path class="cls-10" d="M280.84 461.82c0-2.36-1.97-4.32-4.33-4.32h-13c-2.36 0-4.34 1.95-4.34 4.32V464h21.68v-2.18Z"/> */}
          <g id="powerwall">
            <g>
	            <polygon class="dark-grey" points="254.1,475.7 281.1,469.2 283.8,470.3 257.6,477.1 	"/>
	            <path class="medium-grey" d="M284.4,471.1l0.3,47.1c0,0.2-1.2,0.7-1.4,0.8l-25.6,6.5c-0.3,0.1-0.7-0.2-0.7-0.5l-0.9-47.9l27.5-6.8
		C284,470.2,284.4,470.8,284.4,471.1z"/>
	            <path class="light-grey" d="M269.1,487l15.3-4l0.3,35.3c0,0.2-0.2,0.5-0.4,0.5l-6.4,1.6L269.1,487z"/>
	            <path class="dark-grey" d="M254.4,475.7l3,1.1c0.2,0.1,0.4,0.3,0.4,0.5l0.3,47.6c0,0.4-0.4,0.6-0.7,0.6l-3.2-1.2
		c-0.2-0.2-0.3-0.3-0.3-0.6l-0.1-47.5C253.7,475.9,254.1,475.6,254.4,475.7z"/>
	            <path d="M254.8,475.7l2.6,1.1c0.2,0.1,0.3,0.3,0.3,0.5l0.3,47.5c0,0.4-0.3,0.6-0.7,0.6l-2.8-1.1c-0.2-0.2-0.3-0.3-0.3-0.6
		l-0.1-47.5C254.1,475.9,254.5,475.6,254.8,475.7z"/>
	            <g>
		            <path class="logo" d="M276.2,483.1v1.3l0.4-0.1v-0.8l1.5-0.4v0.8l0.4-0.1v-1.3L276.2,483.1 M276.5,482.6l1.5-0.4
			c0.2-0.1,0.4-0.3,0.4-0.5l-2.4,0.6C276.1,482.5,276.3,482.6,276.5,482.6 M275,484.7c0.2-0.1,0.3-0.3,0.4-0.5l-1.8,0.5V483
			l-0.4,0.1v2.1L275,484.7 M270.1,484.3l1.4-0.4c0.2-0.1,0.4-0.3,0.4-0.5l-2.2,0.6v1.2l1.8-0.5v0.4l-1.4,0.4
			c-0.2,0.1-0.4,0.3-0.5,0.6l2.4-0.6v-1.3l-1.8,0.5L270.1,484.3 M266.6,485.3l1.5-0.4c0.2-0.1,0.4-0.3,0.4-0.5l-2.4,0.6
			C266.3,485.2,266.4,485.3,266.6,485.3 M266.6,486.1l1.5-0.4c0.2-0.1,0.4-0.3,0.4-0.5l-2.4,0.6C266.3,486,266.4,486.2,266.6,486.1
			 M266.6,487l1.5-0.4c0.2-0.1,0.4-0.3,0.4-0.5l-2.4,0.6C266.3,486.9,266.4,487,266.6,487"/>
		            <path class="logo" d="M262.7,486c0,0.2,0.2,0.3,0.4,0.3l0.6-0.2l0,0v1.7l0.4-0.1V486l0,0l0.6-0.2c0.2-0.1,0.4-0.3,0.4-0.5v0
			L262.7,486"/>
	            </g>
	            <polygon class="dark-grey" points="253.8,458.8 280.6,452.4 283.6,453.6 257,459.8 	"/>
	            <path class="medium-grey" d="M257.3,459.6l26-6c0.3-0.1,0.6,0.2,0.6,0.5l0.2,14.7c0,0.2-0.2,0.5-0.4,0.5l-26,6.4c-0.3,0.1-0.6-0.2-0.7-0.5
		l-0.2-15.1C256.9,459.9,257.1,459.7,257.3,459.6z"/>
	            <g>
		            <path class="logo" d="M270.6,467.1l0.6-3.7c0.6-0.2,0.8-0.1,0.8,0.1c0,0,0.4-0.3,0.6-0.6c-0.8-0.2-1.6,0-1.6,0l-0.5,0.7l0,0
			l-0.5-0.4c0,0-0.8,0.2-1.6,0.8c0.2,0.3,0.6,0.3,0.6,0.3c0-0.3,0.2-0.4,0.8-0.6L270.6,467.1L270.6,467.1z"/>
		            <path class="logo" d="M270.6,462.8c0.6-0.2,1.4-0.3,2.1-0.1c0.1-0.2,0.1-0.3,0.1-0.3c-0.8-0.1-1.6,0-2.3,0.2
			c-0.7,0.2-1.4,0.5-2.3,1c0,0,0,0.1,0.1,0.2C269.2,463.3,269.9,463,270.6,462.8L270.6,462.8L270.6,462.8z"/>
              </g>
	            <path class="dark-grey" d="M257.1,476l-3.2-1c-0.2-0.1-0.3-0.2-0.3-0.4l-0.1-15.3c0-0.3,0.3-0.5,0.6-0.4l3.2,0.8c0.2,0.1,0.3,0.2,0.3,0.4
		l0.1,15.5C257.8,475.9,257.4,476.1,257.1,476z"/>
              <path d="M257.1,475.9l-2.9-1c-0.2-0.1-0.3-0.2-0.3-0.4l-0.2-15.3c0-0.3,0.3-0.5,0.6-0.4l2.9,0.8c0.2,0.1,0.3,0.2,0.3,0.4l0.1,15.5
		C257.6,475.8,257.3,476,257.1,475.9z"/>
            </g>
            <path id="Powerwall_Indicator" fill="url(#charge-progress)" d="M255.5,476.2l0.9,0.4c0.2,0.1,0.3,0.2,0.3,0.4l0.2,47.1c0,0.3-0.4,0.6-0.7,0.4
	l-0.9-0.3c-0.2-0.1-0.3-0.2-0.3-0.4l-0.1-47.2C254.9,476.3,255.2,476.1,255.5,476.2z"/>
            <path id="Inverter_Indicator" fill={batteryStatus === 'Charging' ? "#71FF12" : batteryStatus === 'Discharging' ? "#183EFA" : "#A5ADAD"} d="M255.4,459.3l0.9,0.2c0.2,0.1,0.3,0.2,0.3,0.4l0.2,15.1c0,0.3-0.4,0.6-0.7,0.4
	l-0.9-0.3c-0.2-0.1-0.3-0.2-0.3-0.4l-0.1-15C254.8,459.4,255.1,459.2,255.4,459.3z"/>
          </g>
        </svg>
      </div>
    </div>
  )
};

export default Distribution