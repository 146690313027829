import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

const DataTable = ({ rows }) => {
  const options = {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'rgb(51 55 62)' }}>
            <TableCell sx={{ backgroundColor: 'rgb(51 55 62)', position: '-webkit - sticky', position: 'sticky', left: 0, minWidth: '150px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Date</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Total House Usage</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Total Solar Generation</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Total from Powerwall</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Energy from Grid</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Energy to Grid	</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem' }}>Total Energy from Grid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ 'td': { border: 0 }, backgroundColor: 'rgb(51 55 62)' }}
            >
              <TableCell sx={{ backgroundColor: 'rgb(51 55 62)', position: '-webkit - sticky', position: 'sticky', left: 0, minWidth: '150px', color: 'rgb(156 163 175)' }}>{row.date}</TableCell>
              <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.house !== null ? `${row.house.toLocaleString('en', options)} kWh` : ''}</TableCell>
              <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.solar !== null ? `${row.solar.toLocaleString('en', options)} kWh` : ''}</TableCell>
              <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.powerwall !== null ? `${row.powerwall.toLocaleString('en', options)} kWh` : ''}</TableCell>
              <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.from_grid !== null ? `${row.from_grid.toLocaleString('en', options)} kWh` : ''}</TableCell>
              <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.to_grid !== null ? `${row.to_grid.toLocaleString('en', options)} kWh` : ''}</TableCell>
              <TableCell align="right" sx={row.grid < 0 ? { minWidth: '200px', color: 'rgb(204 0 0)' } : { minWidth: '200px', color: 'rgb(156 163 175)' }}>{row.grid !== null ? `${row.grid.toLocaleString('en', options)} kWh` : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ backgroundColor: 'rgb(51 55 62)', '&:last-child td, &:last-child th': { borderBottom: 0, borderTop: 1, borderTopColor: 'rgb(224, 224, 224)' } }}>
            <TableCell sx={{ backgroundColor: 'rgb(51 55 62)', position: '-webkit - sticky', position: 'sticky', left: 0, minWidth: '150px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>Totals:</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.house }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.solar }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.powerwall }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.from_grid }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
            <TableCell align="right" sx={{ minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.to_grid }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
            <TableCell align="right" sx={rows.reduce((acc, obj) => { return acc + obj.grid }, 0) < 0 ? { minWidth: '200px', color: 'rgb(204 0 0)', fontSize: '1rem', fontWeight: 500 } : { minWidth: '200px', color: 'rgb(229 231 235)', fontSize: '1rem', fontWeight: 500 }}>{`${rows.reduce((acc, obj) => { return acc + obj.grid }, 0).toLocaleString('en-US', { options })} kWh`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default DataTable;