import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  format,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  subYears,
} from "date-fns";

import { useStateContext } from '../contexts/ContextProvider';
import { ChartsHeader } from "../components";
import { RangePicker } from "../components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );
  
const SolarChart = () => {
  const { labels, houseData, gridData, solarData, dateRange, setDateRange } = useStateContext();

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Solar",
        backgroundColor: "#EA9300",
        borderRadius: 5,
        base: 0,
        data: solarData,
        maxBarThickness: 100,
      },
      {
        label: "Grid",
        backgroundColor: "#A5ADAD",
        borderRadius: 5,
        base: 0,
        data: gridData,
        maxBarThickness: 100,
      },
      {
        label: "House",
        backgroundColor: "#183EFA",
        borderRadius: 5,
        base: 0,
        data: houseData,
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        }
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        ticks: {
          callback: function(value, index, ticks) {
            return `${value} kWh`;
          }
        }
      }
    },
  }

  const presets = [
    {
      label: "Current Month",
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    },
    {
      label: "Current Year",
      start: startOfYear(new Date()),
      end: endOfYear(new Date()),
    },
    {
      label: "Current True Up Year",
      start: subYears(new Date(new Date().getFullYear(), 9, 23), 1),
      end: new Date(new Date().getFullYear(), 9, 22),
    },
  ]

  const formatDisplayDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", options);
  };

  const onChange = (e) => {
    setDateRange({
      startDate: format(e.startDate, 'yyyy-MM-dd'),
      endDate: format(e.endDate, 'yyyy-MM-dd'),
    })
  }

  return (
    <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl">
      <RangePicker presets={presets} onChange={onChange} />
      <ChartsHeader
        dateRange={`${formatDisplayDate(dateRange.startDate)} - ${formatDisplayDate(dateRange.endDate)}`}
        title="Tesla Solar Report"
      />
      <Bar options={options} data={data} height={98} />
      <p className="flex justify-center p-2 md:ml-6 md:mr-6 relative text-gray-400 italic">Grid usage with a negative number indicates an excess of engery sent back to the grid.</p>
    </div>
  )
};


export default SolarChart;
