import React from "react";
import { addYears, format } from 'date-fns'

import { useStateContext } from '../contexts/ContextProvider';
import { Header as ComponentHeader } from '../components';
import { RangePicker } from "../components";
import { DataTable } from "../components";

import "../App.css";

const Numbers = () => {
  const { pgeData, teslaData, setTrueUpRange } = useStateContext();

  const onChange = (e) => {
    setTrueUpRange({
      startDate: format(e.startDate, 'yyyy-MM-dd'),
      endDate: format(e.endDate, 'yyyy-MM-dd'),
    });
  }

  const presets = [
    {
      label: "'21 - '22 True Up Year",
      start: format(new Date(2021, 9, 23), "yyyy-MM-dd").toString(),
      end: format(new Date(2022, 9, 22), "yyyy-MM-dd").toString(),
    },
    {
      label: "'22 - '23 True Up Year",
      start: format(new Date(2022, 9, 23), "yyyy-MM-dd").toString(),
      end: format(new Date(2023, 9, 22), "yyyy-MM-dd").toString(),
    },
    {
      label: "Current True Up Year",
      start: format(new Date(2023, 9, 23), "yyyy-MM-dd").toString(),
      end: format(new Date(2024, 9, 22), "yyyy-MM-dd").toString(),
    },
    {
      label: "All Time",
      start: format(new Date(2021, 9, 23), "yyyy-MM-dd").toString(),
      end: format(addYears(new Date(), 1), "yyyy-MM-dd").toString(),
    },
  ]

  return (
    <div>
      <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl">
        <RangePicker presets={presets} onChange={onChange} />
        <ComponentHeader title="Tesla Numbers" subtitle="Based on Start Date of Tesla Data" />
        <DataTable rows={teslaData} />
      </div>
      <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl ag-theme-alpine-dark">
        <ComponentHeader title="Utility Numbers" subtitle="Based on Utility Billing Cycle" />
        <DataTable rows={pgeData} />
      </div>
    </div>
  )
}

export default Numbers;
