import React from 'react';
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";

const RangePicker = ({ presets, onChange }) => {
  
  return (
    <div className="flex justify-end pt-10 p-2 md:ml-6 md:mr-6 relative">
      <div style={{ width: "300px" }}>
        <DateRangePickerComponent
                id="daterangepicker"
                placeholder="Select Date Range"
                change={onChange}
                min={new Date(2021, 9, 10)}
                strictMode={true}
                presets={presets}
                openOnFocus={true}
              />
      </div>
    </div>
  );
};

export default RangePicker;
