import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  format,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";

import { useStateContext } from '../contexts/ContextProvider';
import { ChartsHeader } from "../components";
import { RangePicker } from "../components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
  );
  
const Impact = () => {
  const { impactRange, impactData, setImpactRange } = useStateContext();

  const onChange = (e) => {
    setImpactRange({
      startDate: format(e.startDate, 'yyyy-MM-dd'),
      endDate: format(e.endDate, 'yyyy-MM-dd'),
    })
  }

  const data = {
    labels: ['Solar', 'Powerwall', 'Grid'],
    datasets: [{
      data: [impactData.fromSolarPercentage, impactData.fromPowerwallPercentage, impactData.fromGridPercentage],
      backgroundColor: ["#EA9300", "#72FF13", "#A5ADAD"],
      borderWidth: 0,
    }],
  };

  const homeLabel = impactData.totalHouse >= 1000 ? 'MWh Home' : 'kWh Home'
  const solarLabel = impactData.totalSolar >= 1000 ? 'MWh Solar' : 'kWh Solar'
  const totalSolar = impactData.totalSolar >= 1000 ? (impactData.totalSolar * .001).toFixed(1) : impactData.totalSolar
  const totalHouse = impactData.totalHouse >= 1000 ? (impactData.totalHouse * .001).toFixed(1) : impactData.totalHouse

  const data2 = {
    labels: [`${totalSolar} ${solarLabel}`, `${totalHouse} ${homeLabel}`],
    datasets: [
      {
        borderRadius: 7,
        base: 0,
        data: [impactData.totalSolar, impactData.totalHouse],
        backgroundColor: ["#EA9300", "#183EFA"],
        maxBarThickness: 100,
        borderSkipped: false,
      },
    ],
  };

  const formatDisplayDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", options);
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
    },
    cutout: '85%',
  };

  const options = {
    responsive: true,
    plugins: {  
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: 'rgb(229 231 235)',
          font: {
            size: 16,
          }
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false
        }
      }
    },
  }

  const presets = [
    {
      label: "Today",
      start: new Date(),
      end: new Date(),
    },
    {
      label: "Week",
      start: startOfWeek(new Date()),
      end: endOfWeek(new Date()),
    },
    {
      label: "Month",
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    },
    {
      label: "Year",
      start: startOfYear(new Date()),
      end: endOfYear(new Date()),
    },
  ]

  return (
    <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl">
      <RangePicker presets={presets} onChange={onChange} />
      <ChartsHeader
        dateRange={`${formatDisplayDate(impactRange.startDate)} - ${formatDisplayDate(impactRange.endDate)}`}
        title="Tesla Solar Impact Report"
      />
      <div className="impact-charts pt-10 p-2 md:ml-6 md:mr-6 relative">
        <div className='impact-div flex items-center'>
          <h3 className="text-xl text-gray-400 self-powered">Self Powered</h3>
          <div className="flex items-center dark:text-gray-200" style={{ width: "300px", fontSize: "1.2em" }}>
            <ul>
              <li className='solarMarker'>{` ${impactData.fromSolarPercentage}% Solar`}</li>
              <li className='powerwallMarker'>{` ${impactData.fromPowerwallPercentage}% Powerwall`}</li>
              <li className='gridMarker'>{` ${impactData.fromGridPercentage}% Grid`}</li>
            </ul>
          </div>
          <div className='impact'>
            <span id="percentage">{`${impactData.selfPoweredPercentage}%`}</span>
            
            <Doughnut id="impact-donut" options={options1} data={data} />
          </div>
        </div>
        <div className='offset-div flex items-center'>
          <h3 className="text-xl text-gray-400 solar-offset">Solar Offset</h3>
          <div className="flex items-center dark:text-gray-200" style={{ width: "300px", fontSize: "1.2em" }}>
            <ul>
              <li className='offset'>{` ${impactData.totalSolarPercentage}% Engergy Offset`}</li>
            </ul>
          </div>
          <div>
            <Bar options={options} data={data2} height={'300%'} />
          </div>
        </div>
      </div>
    </div>
  )
};


export default Impact;
