import React from "react";

import { useStateContext } from '../contexts/ContextProvider';
import { Header as ComponentHeader } from '../components';
import { TopSolarTable } from "../components";
import { TopExportTable } from "../components";

import "../App.css";

const TopTen = () => {
  const { topSolar, topExport } = useStateContext();

  return (
    <div>
      <div className="m-4 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg rounded-3xl">
        <ComponentHeader title="Top 10 Solar Generation Days" subtitle="Based on total solar generated" />
        <TopSolarTable rows={topSolar} />
        <ComponentHeader title="Top 10 Energy Export Days" subtitle="Based on total energy exported to PG&E" />
        <TopExportTable rows={topExport} />
      </div>
    </div>
  )
}

export default TopTen;
