import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { useStateContext } from '../contexts/ContextProvider';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { currentColor, activeMenu, setActiveMenu, setScreenSize, screenSize, battery, batteryStatus } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const getBatteryStatus = () => {
    if (batteryStatus === 'Discharging') {
      return <p className='discharge'> {batteryStatus}</p>
    } else if (batteryStatus === 'Charging') {
      return <p className='charge'> {batteryStatus}</p>
    } else {
      return <p className='standby'> {batteryStatus}</p>
    }
  }
  
  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />
      <div className='dark:text-gray-200 text-2xl flex items-center pt-2 pr-4'>
        <>{getBatteryStatus()}</>
        <svg version="1.1" id="Layer_1" viewBox="0 0 48 48" enable-background="new 0 0 48 48" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
          <defs>
	          <linearGradient id="charge-progress" x1="-.015" y1="1" x2="0" y2="0"> 
              <stop id="stop1" offset={battery/100} stop-color="#71FF12"/> 
              <stop id="stop2" offset={battery/100 - .1} stop-color="#000000"/> 
            </linearGradient>
            <style>
              {`.light-grey{fill:#eee;}
                .medium-grey{fill:#e8e8e6;}
                .dark-grey{fill:#a3a39f;}
                .logo{fill:#5e5e5e;}`}
            </style>
          </defs>
          <g>
            <polygon class="dark-grey" points="12.4,7.5 33.1,2.5 35.1,3.3 15.2,8.5 	"/>
            <path class="medium-grey" d="M35.6,3.9l0.2,35.9c0,0.1-1,0.5-1.1,0.6l-19.6,5c-0.2,0.1-0.5-0.1-0.5-0.4L14,8.5l21-5.1
              C35.2,3.2,35.6,3.7,35.6,3.9z"/>
            <path class="light-grey" d="M23.9,16.1L35.6,13l0.2,27c0,0.1-0.1,0.4-0.4,0.4l-4.9,1.2L23.9,16.1z"/>
            <path class="dark-grey" d="M12.7,7.5l2.3,0.8c0.1,0.1,0.4,0.2,0.4,0.4L15.5,45c0,0.4-0.4,0.5-0.5,0.5l-2.4-1c-0.1-0.1-0.2-0.2-0.2-0.5
              L12.3,7.8C12.2,7.6,12.4,7.4,12.7,7.5z"/>
            <path d="M13,7.5l2,0.8c0.1,0.1,0.2,0.2,0.2,0.4L15.5,45c0,0.4-0.2,0.5-0.5,0.5l-2.1-0.8c-0.1-0.1-0.2-0.2-0.2-0.5L12.5,7.9
              C12.4,7.6,12.8,7.4,13,7.5z"/>
            <g>
              <path class="logo" d="M29.2,13.1v1l0.4-0.1v-0.6l1.2-0.4v0.6l0.4-0.1v-1L29.2,13.1 M29.5,12.8l1.2-0.4c0.1-0.1,0.4-0.2,0.4-0.4
                l-1.8,0.5C29.2,12.7,29.4,12.8,29.5,12.8 M28.4,14.3c0.1-0.1,0.2-0.2,0.4-0.4l-1.4,0.4V13L27,13.1v1.6L28.4,14.3 M24.6,14.1
                l1.1-0.4c0.1-0.1,0.4-0.2,0.4-0.4l-1.7,0.5v1l1.4-0.4v0.4l-1.1,0.4c-0.1,0.1-0.4,0.2-0.4,0.5l1.8-0.5v-1l-1.4,0.4L24.6,14.1
                M22,14.8l1.2-0.4c0.1-0.1,0.4-0.2,0.4-0.4l-1.9,0.5C21.7,14.7,21.8,14.8,22,14.8 M22,15.4l1.2-0.4c0.1-0.1,0.4-0.2,0.4-0.4
                l-1.9,0.5C21.7,15.3,21.8,15.5,22,15.4 M22,16.1l1.2-0.4c0.1-0.1,0.4-0.2,0.4-0.4l-1.9,0.5C21.7,16,21.8,16.1,22,16.1"/>
              <path class="logo" d="M19,15.3c0,0.1,0.1,0.2,0.4,0.2l0.5-0.1l0,0v1.3l0.4-0.1v-1.3l0,0l0.5-0.1c0.1-0.1,0.4-0.2,0.4-0.4l0,0
                L19,15.3"/>
            </g>
          </g>
          <path id="Powerwall_Indicator" fill="url(#charge-progress)" d="M13.5,7.9l0.7,0.4c0.1,0.1,0.2,0.1,0.2,0.4l0.1,35.9c0,0.2-0.4,0.5-0.5,0.4l-0.7-0.2
	c-0.1-0.1-0.2-0.1-0.2-0.4L13,8.2C13,7.9,13.3,7.8,13.5,7.9z"/>
        </svg> <span>{battery}%</span>
      </div>
    </div>
  );
};

export default Navbar;
