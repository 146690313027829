import React, { createContext, useContext, useState, useEffect } from "react";
import {
  addYears,
  format,
  isAfter,
  startOfMonth,
  endOfMonth,
  startOfToday,
  subYears,
} from "date-fns";
import { io } from "socket.io-client";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [dateRange, setDateRange] = useState({
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  });
  const [impactRange, setImpactRange] = useState({
    startDate: format(startOfToday(), "yyyy-MM-dd"),
    endDate: format(startOfToday(), "yyyy-MM-dd"),
  });
  const [trueUpRange, setTrueUpRange] = useState({
    startDate: isAfter(new Date(), new Date(new Date().getFullYear(), 9, 23))
      ? format(
          new Date(new Date().getFullYear(), 9, 23),
          "yyyy-MM-dd"
        ).toString()
      : format(
          subYears(new Date(new Date().getFullYear(), 9, 23), 1),
          "yyyy-MM-dd"
        ).toString(),
    endDate: isAfter(new Date(), new Date(new Date().getFullYear(), 9, 23))
      ? format(
          addYears(new Date(new Date().getFullYear(), 9, 22), 1),
          "yyyy-MM-dd"
        ).toString()
      : format(
          new Date(new Date().getFullYear(), 9, 22),
          "yyyy-MM-dd"
        ).toString(),
  });
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#cc0000");
  const [currentMode, setCurrentMode] = useState("Dark");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [filteredData, setFilteredData] = useState([]);
  const [solarData, setSolarData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [houseData, setHouseData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [impactData, setImpactData] = useState({});
  const [battery, setBattery] = useState();
  const [modified, setModified] = useState("");
  const [batteryStatus, setBatteryStatus] = useState("");
  const [solarHouse, setSolarHouse] = useState("hidden");
  const [solarBattery, setSolarBattery] = useState("hidden");
  const [solarGrid, setSolarGrid] = useState("hidden");
  const [batteryHouse, setBatteryHouse] = useState("hidden");
  const [gridHouse, setGridHouse] = useState("hidden");
  const [gridBattery, setGridBattery] = useState("hidden");
  const [solarPower, setSolarPower] = useState(0);
  const [loadPower, setLoadPower] = useState(0);
  const [gridPower, setGridPower] = useState(0);
  const [batteryPower, setBatteryPower] = useState(0);
  const [teslaData, setTeslaData] = useState([]);
  const [pgeData, setPgeData] = useState([]);
  const [topSolar, setTopSolar] = useState([]);
  const [topExport, setTopExport] = useState([]);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    const socket = io("https://teslasolar.report", {
      path: "/socket.io",
      auth: {
        token: "hJQNMS4ReNYWWmySVP9yQk7S1mxrpOHU",
      },
    });
    socket.on("connect", () => {});

    socket.on("update", (data) => {
      setBattery(data.impactRange.battery_percentage);
      setModified(format(new Date(data.impactRange.modified), "p"));
      setBatteryStatus(data.impactRange.battery_status);
      setLabels(data.dateRange.map((x) => x.date));
      setSolarData(data.dateRange.map((x) => x.solar));
      setGridData(data.dateRange.map((x) => x.grid));
      setHouseData(data.dateRange.map((x) => x.house));
      setImpactData({
        fromSolarPercentage: data.impactRange.from_solar_percentage,
        fromPowerwallPercentage: data.impactRange.from_powerwall_percentage,
        fromGridPercentage: data.impactRange.from_grid_percentage,
        selfPoweredPercentage: data.impactRange.self_powered_percentage,
        totalSolarPercentage: data.impactRange.total_solar_percentage,
        totalSolar: data.impactRange.total_solar,
        fromSolar: data.impactRange.from_solar,
        fromPowerwall: data.impactRange.from_powerwall,
        fromGrid: data.impactRange.from_grid,
        totalHouse: data.impactRange.total_house,
      });
      setTeslaData(data.trueUpRange.data[0].tesla);
      setPgeData(data.trueUpRange.data[1].pge);
      setTopSolar(data.topTen.data[0].solar);
      setTopExport(data.topTen.data[1].net_grid);
      setSolarPower(parseFloat(data.distribution.solar_power));
      setLoadPower(parseFloat(data.distribution.load_power));
      setGridPower(parseFloat(data.distribution.grid_power));
      setBatteryPower(parseFloat(data.distribution.battery_power));
      data.distribution.solar_power > 0
        ? setSolarHouse("visible")
        : setSolarHouse("hidden");
      if (data.distribution.battery_power > 0) {
        setBatteryHouse("visible");
        setSolarBattery("hidden");
      } else if (
        data.distribution.battery_power < 0 &&
        data.distribution.solar_power > 0
      ) {
        setBatteryHouse("hidden");
        setSolarBattery("visible");
      } else {
        setBatteryHouse("hidden");
        setSolarBattery("hidden");
      }
      if (data.distribution.grid_power > 0) {
        setGridHouse("visible");
        setSolarGrid("hidden");
      } else if (
        data.distribution.grid_power < 0 &&
        data.distribution.solar_power > 0
      ) {
        setSolarGrid("visible");
        setGridHouse("hidden");
      } else if (data.distribution.grid_power === 0) {
        setSolarGrid("hidden");
        setGridHouse("hidden");
      } else {
        setGridHouse("hidden");
        setSolarGrid("hidden");
      }
    });

    socket.emit("date_change", {
      dateRangeStartDate: dateRange.startDate,
      dateRangeEndDate: dateRange.endDate,
      impactRangeStartDate: impactRange.startDate,
      impactRangeEndDate: impactRange.endDate,
      trueUpRangeStartDate: trueUpRange.startDate,
      trueUpRangeEndDate: trueUpRange.endDate,
    });

    return () => {
      socket.disconnect();
    };
  }, [dateRange, impactRange, trueUpRange]);

  const url = `https://teslasolar.report/api/v2/formatted?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
  const impactUrl = `https://teslasolar.report/api/v2/impact?start_date=${impactRange.startDate}&end_date=${impactRange.endDate}`;
  const distributionUrl = "https://teslasolar.report/api/v2/distribution";
  const trueUpUrl = `https://teslasolar.report/api/v2/sheet?start_date=${trueUpRange.startDate}&end_date=${trueUpRange.endDate}`;
  const topTenUrl = `https://teslasolar.report/api/v2/top_ten`;
  // const url = `http://localhost:5000/api/v2/formatted?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
  // const impactUrl = `http://localhost:5000/api/v2/impact?start_date=${impactRange.startDate}&end_date=${impactRange.endDate}`;
  // const distributionUrl = "http://localhost:5000/api/v2/distribution";

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(url);
      const data = await res.json();
      setLabels(data.data.map((x) => x.date));
      setSolarData(data.data.map((x) => x.solar));
      setGridData(data.data.map((x) => x.grid));
      setHouseData(data.data.map((x) => x.house));
    };
    fetchData();
  }, [url, setGridData, setHouseData, setLabels]);

  useEffect(() => {
    const fetchImpactData = async () => {
      const res = await fetch(impactUrl);
      const data = await res.json();
      setImpactData({
        fromSolarPercentage: data.data.from_solar_percentage,
        fromPowerwallPercentage: data.data.from_powerwall_percentage,
        fromGridPercentage: data.data.from_grid_percentage,
        selfPoweredPercentage: data.data.self_powered_percentage,
        totalSolarPercentage: data.data.total_solar_percentage,
        totalSolar: data.data.total_solar,
        fromSolar: data.data.from_solar,
        fromPowerwall: data.data.from_powerwall,
        fromGrid: data.data.from_grid,
        totalHouse: data.data.total_house,
      });
      setBattery(data.data.battery_percentage);
      setModified(format(new Date(data.data.modified), "p"));
      setBatteryStatus(data.data.battery_status);
    };
    fetchImpactData();
  }, [impactUrl]);

  useEffect(() => {
    const fetchDistributionData = async () => {
      const res = await fetch(distributionUrl);
      const data = await res.json();

      setSolarPower(parseFloat(data.data.solar_power));
      setLoadPower(parseFloat(data.data.load_power));
      setGridPower(parseFloat(data.data.grid_power));
      setBatteryPower(parseFloat(data.data.battery_power));
      data.data.solar_power > 0
        ? setSolarHouse("visible")
        : setSolarHouse("hidden");
      if (data.data.battery_power > 0) {
        setBatteryHouse("visible");
        setSolarBattery("hidden");
      } else if (data.data.battery_power < 0 && data.data.solar_power > 0) {
        setBatteryHouse("hidden");
        setSolarBattery("visible");
      } else {
        setBatteryHouse("hidden");
        setSolarBattery("hidden");
      }
      if (data.data.grid_power > 0) {
        setGridHouse("visible");
        setSolarGrid("hidden");
      } else if (data.data.grid_power < 0 && data.data.solar_power > 0) {
        setSolarGrid("visible");
        setGridHouse("hidden");
      } else if (data.data.grid_power === 0) {
        setSolarGrid("hidden");
        setGridHouse("hidden");
      } else {
        setGridHouse("hidden");
        setSolarGrid("hidden");
      }
    };
    fetchDistributionData();
  }, []);

  useEffect(() => {
    const fetchTrueUpData = async () => {
      const res = await fetch(trueUpUrl);
      const data = await res.json();
      setTeslaData(data.data[0].tesla);
      setPgeData(data.data[1].pge);
      console.log(teslaData);
    };
    fetchTrueUpData();
  }, [trueUpUrl]);

  useEffect(() => {
    const fetchTopTenData = async () => {
      const res = await fetch(topTenUrl);
      const data = await res.json();
      setTopSolar(data.data[0].solar);
      setTopExport(data.data[1].net_grid);
    };
    fetchTopTenData();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        dateRange,
        setDateRange,
        filteredData,
        setFilteredData,
        labels,
        setLabels,
        solarData,
        setSolarData,
        gridData,
        setGridData,
        houseData,
        setHouseData,
        impactData,
        setImpactData,
        impactRange,
        setImpactRange,
        battery,
        setBattery,
        modified,
        setModified,
        batteryStatus,
        setBatteryStatus,
        solarHouse,
        setSolarHouse,
        solarBattery,
        setSolarBattery,
        solarGrid,
        setSolarGrid,
        batteryHouse,
        setBatteryHouse,
        gridHouse,
        setGridHouse,
        gridBattery,
        setGridBattery,
        solarPower,
        setSolarPower,
        loadPower,
        setLoadPower,
        gridPower,
        setGridPower,
        batteryPower,
        setBatteryPower,
        trueUpRange,
        setTrueUpRange,
        teslaData,
        setTeslaData,
        pgeData,
        setPgeData,
        topSolar,
        setTopSolar,
        topExport,
        setTopExport,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
